/**
 * GTM DataLayer 인터페이스
 * @author 장유현
 */

// GTM event Type (이벤트 type 구분)
export function eventType (type) {
  let eventValue = ''
  switch (type) {
    case 'cart':
      eventValue = 'add_to_cart'
      break

    case 'wishlist':
      eventValue = 'add_to_wishlist'
      break

    case 'checkout':
      eventValue = 'begin_checkout'
      break

    case 'view':
      eventValue = 'view_item'
      break

    case 'purchase':
      eventValue = 'purchase'
      break

    default:
      break
  }
  return eventValue
}

// GTM 단건 dataLayer Push
export function pushGtmData (item, type) {
  console.log('### GTM Start ###')
  console.log('### GTM item ::: ', item)
  console.log('### GTM type ::: ', type)

  window.dataLayer = window.dataLayer || []

  const event = eventType(type)

  if (event === 'view_item') { // 상품 상세페이지 접근 시 dataLayer 초기화
    window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
  }

  window.dataLayer.push({
    event,
    ecommerce: {
      currency: 'KRW',
      value: (item.pdPrc ? item.pdPrc : item.price ? item.price : item.sleUtpc ? item.sleUtpc : '') * (item.spbkQy ? item.spbkQy : 1),
      items: [
        {
          item_id: item.pdNo ? item.pdNo : item.bunPdNo ? item.bunPdNo : '',
          item_name: item.exhPdNm ? item.exhPdNm : item.pdNm ? item.pdNm : '',
          item_brand: item.brndNm ? item.brndNm : '',
          item_category: item.onlSclCd ? item.onlSclCd.slice(0, 1) : '',
          item_category2: item.onlSclCd ? item.onlSclCd.slice(3, 6) : '',
          item_variant: item.variant ? item.variant : item.optCn ? item.optCn : '',
          price: item.pdPrc ? item.pdPrc : item.price ? item.price : '',
          quantity: item.spbkQy ? item.spbkQy : 1,
          pkgYn: event === 'add_to_cart' && item.pkgYn ? item.pkgYn === 'Y' ? item.pkgYn : undefined : undefined
        }
      ]
    }
  })

  console.log('### GTM End ###')
}

// GTM 다건 dataLayer Push
export function pushGtmListData (item, type) { // 결제 시도, 구매 완료의 경우
  console.log('### GTM Start ###')
  console.log('### GTM item ::: ', item)
  console.log('### GTM type ::: ', type)

  window.dataLayer = window.dataLayer || []

  const event = eventType(type)
  let dataList = []

  if (event === 'purchase') { // 결제 성공 시
    dataList = item.orPdList ? item.orPdList : ''
  } else if (event === 'begin_checkout') { // 결제 시작 시
    dataList = item.gtmDataList ? item.gtmDataList : ''
  } else {
    dataList = item || ''
  }

  if (event === 'begin_checkout' || event === 'purchase') { // 결제 시작 or 결제 성공 시 dataLayer 초기화
    window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
  }

  let totalValue = 0 // 총 결제금액
  const itemList = [] // GTM items 전달을 위한 리스트 저장
  const cpnList = [] // 결제 시작 시 쿠폰 리스트 저장

  // 추후 수정
  if (event === 'begin_checkout') { // 결제 시작 시 요구하는 데이터가 다르기에 분기처리
    if (dataList.cpnGtmList && dataList.cpnGtmList.length > 0) {
      dataList.cpnGtmList.forEach((e) => {
        cpnList.push(e.cpnNm)
      })
    }

    dataList.dataForm.forEach((e) => {
      totalValue += (e.pdPrc ? e.pdPrc : e.price ? e.price : e.sleUtpc ? e.sleUtpc : '') * (e.spbkQy ? e.spbkQy : '')

      itemList.push({
        item_id: e.pdNo ? e.pdNo : e.bunPdNo ? e.bunPdNo : '',
        item_name: e.exhPdNm ? e.exhPdNm : e.pdNm ? e.pdNm : '',
        item_category: e.onlSclCd ? e.onlSclCd.slice(0, 1) : '',
        item_category2: e.onlSclCd ? e.onlSclCd.slice(3, 6) : '',
        item_variant: e.optPdYn === 'Y' ? e.optCn : undefined,
        item_brand: e.brndNm ? e.brndNm : undefined,
        price: e.pdPrc ? e.pdPrc : e.price ? e.price : e.sleUtpc ? e.sleUtpc : '',
        quantity: e.spbkQy ? e.spbkQy : '',
        coupon: cpnList,
        pkgYn: e.pkgChkYn ? e.pkgChkYn === 'Y' ? e.pkgChkYn : undefined : undefined
      })
    })
  } else if (event === 'add_to_cart') { // 장바구니 담기 시
    dataList.forEach((e) => {
      totalValue += (e.pdPrc ? e.pdPrc : e.price ? e.price : e.sleUtpc ? e.sleUtpc : '') * (e.spbkQy ? e.spbkQy : '')

      itemList.push({
        item_id: e.pdNo ? e.pdNo : e.bunPdNo ? e.bunPdNo : '',
        item_name: e.exhPdNm ? e.exhPdNm : e.pdNm ? e.pdNm : '',
        item_category: e.onlSclCd ? e.onlSclCd.slice(0, 1) : '',
        item_category2: e.onlSclCd ? e.onlSclCd.slice(3, 6) : '',
        price: e.pdPrc ? e.pdPrc : e.price ? e.price : e.sleUtpc ? e.sleUtpc : '',
        quantity: e.spbkQy ? e.spbkQy : '',
        item_brand: e.brndNm ? e.brndNm : '',
        item_variant: e.variant ? e.variant : undefined,
        pkgYn: e.pkgYn ? e.pkgYn === 'Y' ? e.pkgYn : undefined : undefined
      })
    })
  } else {
    totalValue = item.pgPayInfo?.totPyAm ?? ''

    dataList.forEach((element) => {
      itemList.push({
        item_id: element.pdNo ? element.pdNo : element.bunPdNo ? element.bunPdNo : '',
        item_name: element.exhPdNm ? element.exhPdNm : element.pdNm ? element.pdNm : '',
        item_category: element.onlSclCd ? element.onlSclCd.slice(0, 1) : '',
        item_category2: element.onlSclCd ? element.onlSclCd.slice(3, 6) : '',
        item_variant: element.optCn ? element.optCn : undefined,
        item_brand: element.brndNm ? element.brndNm : undefined,
        price: element.pdPrc ? element.pdPrc : element.price ? element.price : element.sleUtpc ? element.sleUtpc : '',
        quantity: element.spbkQy ? element.spbkQy : '',
        pkgYn: event === 'purchase' && element.pkgChkYn === 'Y' ? element.pkgChkYn : undefined
      })
    })
  }

  if (event === 'purchase') { // 결제 성공 시 요구하는 데이터가 다르기에 분기처리
    window.dataLayer.push({
      event,
      ecommerce: {
        currency: 'KRW',
        value: totalValue || 0,
        transaction_id: item.orNo ? item.orNo : '',
        shipping: item.dlvcAm ? item.dlvcAm : '',
        tax: item.pgPayInfo?.vat ?? '',
        discount: item.cpnDctAm || 0,
        items: itemList || ''
      }
    })
  } else {
    if (totalValue && totalValue < 30000 && event === 'begin_checkout') {
      totalValue += 3000 // 3만원 미만 금액일 경우 배송비 추가
    }

    window.dataLayer.push({
      event,
      ecommerce: {
        currency: 'KRW',
        value: totalValue || 0,
        items: itemList || ''
      }
    })
  }

  console.log('### GTM End ###')
}

// GTM 검색 dataLayer Push
export function pushSearchGtmData (item) {
  console.log('### GTM Start ###')
  console.log('### GTM item ::: ', item)
  window.dataLayer = window.dataLayer || []

  window.dataLayer.push({
    event: 'search',
    search_term: item || ''
  })

  console.log('### GTM End ###')
}

// GTM 회원가입 dataLayer Push
export function pushSignUpGtmData (item, mbMngNo) {
  window.dataLayer = window.dataLayer || []

  console.log('### GTM Start ###')
  console.log('### GTM item ::: ', item)
  console.log('### GTM mbMngNo ::: ', mbMngNo)

  window.dataLayer.push({
    event: 'sign_up',
    method: item || '',
    user_number: mbMngNo || ''
  })

  console.log('### GTM End ###')
}

// GTM 배너 클릭 dataLayer Push
export function pushBannerGtmData (item) {
  window.dataLayer = window.dataLayer || []

  console.log('### GTM Start ###')
  console.log('### GTM item ::: ', item)

  window.dataLayer.push({
    event: 'click_banner',
    banner_name: item || ''
  })

  console.log('### GTM End ###')
}
