import * as _ from 'lodash'
// 인자: 전시소재번호 → return component명
export const getExhMtrlTyCd = (cd, exhCnrId) => {
  switch (cd) {
    case '01':
      return 'banner'
    case '02':
      return 'product'
    case '03':
      return 'texts'
    case '04':
      return 'movies'
    case '05':
      return 'buttons'
    case '06':
      return 'categoryProduct'
    case '07':
      return 'html'
    case '08':
      return 'link'
    case '09':
      return 'bundleProducts'
    case '10':
      return 'newProducts'
    case '11':
      return 'contents' // 컨텐츠형: 수정 필요 - 레디스에서 사용할 명칭 아직 모름
    case '12':
      if (exhCnrId === 'C101') {
        return 'exbt'
      } else {
        return 'etcExbt'
      }
    case '13':
      return 'productReview'
    case '14':
      return 'htmlProduct'
    default:
      return ''
  }
}

export const setExhMtrlApi = (cd) => {
  switch (cd) {
    case 'banner':
      return '/ds/exh/selBanr'
    case 'product':
      return '/ds/exh/selProduct'
    case 'texts':
      return '/ds/exh/selText'
    case 'movies':
      return '/ds/exh/selMovie'
    case 'buttons':
      return '/ds/exh/selButton'
    case 'categoryProduct':
      return '/ds/exh/selCategory'
    case 'html':
      return '/ds/exh/selHtml'
    case 'link':
      return '/ds/exh/selLink'
    case 'bundleProducts':
      return '/ds/exh/selBundle'
    case 'newProducts':
      return '/ds/exh/selNewProduct'
    case 'contents':
      // return '/ug/ugm/selMainHofCtenList' // 컨텐츠형: 수정 필요 - 레디스에서 사용할 명칭 아직 모름
      return '/ds/exh/selContent' // 컨텐츠형: 수정 필요 - 레디스에서 사용할 명칭 아직 모름
    case 'exbt':
      return '/ds/exh/selExbt'
    case 'etcExbt':
      return '/ds/exh/selExbt'
    case 'productReview':
      return '/ds/exh/selReview'
    case 'htmlProduct':
      return '/ds/exh/selHtmlProduct'
    default:
      return ''
  }
}

/**
 * 상품 전시유형코드별 전시유형 class명 조회
 * @param {String classCd} 상품 전시 유형 공통코드
 * @returns Object
 *  minSize - 상품 노출 최소 수량
 *  maxSize - 상품 노출 최대 수량
 *  pageSize - 페이징할 상품 단위
 *  class - style class명
 *  showRndBtn - 전체보기 버튼 노출 여부
 */
export const getProdExhType = (classCd) => {
  switch (classCd) {
    case '01': // 리스트형
      return { minSize: 4, maxSize: 20, pageSize: 10, class: ['type-card'], showEmblem: true, showFlag: true, showRndBtn: false }
    case '02': // 리스트_판매알림형
      return { minSize: 4, maxSize: 4, pageSize: 4, class: ['type-card'], showEmblem: true, showFlag: true, showRndBtn: true }
    case '03': // 1단형
      return { minSize: 4, maxSize: 20, pageSize: 4, class: ['type-card'], showEmblem: true, showFlag: true, showRndBtn: false }
    case '04': // 2단형
      return { minSize: 4, maxSize: 20, pageSize: 4, class: ['type-card'], showEmblem: true, showFlag: true, showRndBtn: false }
    case '05': // 가로 스와이프형
      return { minSize: 5, maxSize: 20, pageSize: 5, class: ['type-card'], showEmblem: true, showFlag: true, showRndBtn: true }
    // case '06': // 5단형 - 07/05 전시구조도 2.0 퍼블 완료시 추가 예정
    //   return { minSize: 5, maxSize: 20, pageSize: 5, class: ['type-card'], showRndBtn: false }
    case '06': // 가로 스와이프형 소형 - 소형 전시유형 공통화를 위해 추가
      return { minSize: 5, maxSize: 20, pageSize: 5, class: ['type-card'], showEmblem: true, showFlag: false, showRndBtn: true }
    case '07': // 상품 6개 조합형
      return { minSize: 5, maxSize: 30, pageSize: 5, class: ['type-card'], showEmblem: true, showFlag: true, showRndBtn: true }
    case '08': // 랭킹_리스트형
      return { minSize: 10, maxSize: 20, pageSize: 10, class: ['type-card'], showRndBtn: false }
    case '09': // 랭킹_스와이프형
      return { minSize: 5, maxSize: 20, pageSize: 5, class: ['type-card'], showEmblem: true, showFlag: true, showRndBtn: false }
    case '10': // 상품 리뷰형
      return { minSize: 5, maxSize: 20, pageSize: 5, class: ['type-card'], showEmblem: false, showFlag: false, showRndBtn: true }
    case '12': // 기획전 목록형
      return { minSize: 3, maxSize: 18, pageSize: 1, class: ['type-list'], showEmblem: false, showFlag: false, showRndBtn: true }
    default:
      return { minSize: 0, pageSize: 4, class: ['type-card'] }
  }
}

/**
* @description 카테고리 타입별 상품검색 그룹핑 조건 및 인덱스
* 10 : 연령대
* 20 : 가격대
* 30 : 평점
* 40 : 대카테고리
*/
export const groupResult = {
  10: { index: 9, queryNm: 'tOrdr' },
  20: { index: 8, queryNm: 'pdPrcRange' },
  30: { index: 7, queryNm: 'revwStsc' },
  40: { index: 1, queryNm: 'largeExhCtgrNo' }
}

/**
 * 추천셋 기본 파라미터 설정
 * @param {Object} exhData 구좌 데이터 Object
 * @param {Object} mtrlData 소재 데이터 Object
 * @returns 소재별 솔루션 url, 구좌 개인화여부
 */
export function setDefaultParams (exhData, mtrlData) {
  const params = {}
  // params.id = 'C206' // 추천셋 코너ID - 변경시 수정 필요
  params.exhCmptmtInfo = {}
  params.searchApi = {} // 검색 솔루션 전용
  params.recmApi = {} // 추천 솔루션 전용
  if (exhData) {
    params.exhCmptmtInfo.exhCmptmtId = exhData.exhCmptmtId // 구좌 ID
    // const hdcpy1 = exhData.hdcpy1 && exhData.hdcpy1 !== '_대카' ? exhData.hdcpy1 : '' // 구좌 메인타이틀1
    const hdcpy1 = exhData.hdcpy1 ? exhData.hdcpy1 : ''// 구좌 메인타이틀1
    const hdcpy2 = exhData.hdcpy2 ? exhData.hdcpy2 : ''// 구좌 메인타이틀2
    params.exhCmptmtInfo.hdcpy = hdcpy1 + ' ' + hdcpy2
    params.exhCmptmtInfo.lginYn = exhData.lginYn // 구좌 개인화 여부
  }
  if (mtrlData) {
    params.exhCmptmtInfo.exhMtrlRgMthdCd = mtrlData.exhMtrlRgMthdCd // 전시 소재 등록 유형(수기등록, 검색 솔루션, 추천 솔루션)
    params.exhCmptmtInfo.insdYn = mtrlData.insdYn || '' // api 구분(Y : 내부 api(수동, 추천), N : 외부 api(검색))
    if (mtrlData.soltnInrkApiNm !== '') {
      const url = (mtrlData.insdYn === 'Y' ? process.env.API_BASE_URL : process.env.API_SSN_BASE_URL) + _.unescape(mtrlData.soltnInrkApiNm)
      const searchParam = Object.fromEntries(new URL(url).searchParams)
      params.exhCmptmtInfo.url = url.split('?')[0]
      // 검색 솔루션(searchApi) 등록 url 쿼리 기본정보 추가
      if (mtrlData.exhMtrlRgMthdCd === '20') {
        params.searchApi = searchParam
      }
    }
  }
  return params
}

/**
 * 코너의 구좌 조회
 * @param {id, $api} 코너 ID
 * @returns 구좌 List
 */
export const getAccntList = (id, $api) => {
  const params = {
    exhCnrId: id
  }

  const accountList = $api.post('/ds/exh/selExh', params).then((res) => {
    if (res.data.success) {
      return res.data.data.filter((item) => { return item.aplMdiaPcomYn === 'Y' })
    }
  })

  return accountList
}

/**
 * 각 구좌의 소재 조회
 * @param {accountList, $api} 구좌 List
 * @returns 소재 List
 */
export async function getMtrlsList (accountList, $api) {
  const promiseList = accountList.map(async (e) => {
    try {
      const resultList = []
      for (let i = 0; i < e.mtrls.length; i++) {
        const obj = e.mtrls[i]
        // return: 소재별 component명
        const typeCd = getExhMtrlTyCd(obj.exhMtrlTyCd)
        // return: 소재별 api
        const url = setExhMtrlApi(typeCd)

        let params = {
          exhCmptmtId: e.exhCmptmtId,
          exhMtrlNo: obj.exhMtrlNo
        }

        // 상품 소재: mo(20), pc(10) 구분 필요
        if (typeCd === 'product') {
          params = {
            ...params,
            deviceTypeCd: 10
          }
        }

        // return 값 resultList에 push
        resultList.push(
          await $api.post(url, params).then((res) => {
            if (res.data.success) {
              return res.data.data
            }
          })
        )
      }

      return resultList
    } catch (e) {
      console.log('e', e)
      return null
    }
  })

  const mtrlsList = await Promise.all(promiseList)
  return mtrlsList
}

/**
 * 팝업 로컬 스토리지 만료정보 조회
 * @param {*} key 팝업ID
 * @returns
 *  만료일 > 오늘 현재 : true
 *  그외 : false
 */
export function getPopLocalStorage (key) {
  const getStorage = localStorage.getItem('popup')
  let today = new Date()
  today = today.setTime(today.getTime())
  if (getStorage) {
    const storage = JSON.parse(getStorage)
    const expireTime = JSON.parse(getStorage)[key]
    // 만료일 도달한 팝업 쿠키 삭제
    if (expireTime && expireTime === today) {
      delete storage[key]
      localStorage.setItem('popup', JSON.stringify(storage))
    }
    return (expireTime && expireTime > today) || false
  } else {
    return false
  }
}
/**
 * 광고 팝업 세션 스토리지 정보
 * @param {*} key 팝업 키
 * @returns 세션 스토리지 저장여부
 */
export function getPopSessionStorage (key) {
  return !!sessionStorage.getItem(`closeAd-${key}`)
}
